export default [
	{
		header: 'Movement',
		text:
			'The guide seems to be on the bright side. Not a lot of movement huh?',
		button: "Let's fix that"
	},
	{
		header: 'Myself',
		text:
			"The man in the middle? That's me! Want to learn more... You are one click away.",
		button: 'Know me more'
	},
	{
		header: '"Masterpieces"',
		text: "Some of the things I've done in the past",
		button: 'Look them up'
	}
];
